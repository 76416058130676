.modal-information {
  height: 340px !important;
  width: 630px !important;

  .ant-modal-content {
    padding: 40px 75px;
    border-radius: 20px;
    height: 340px;
    width: 630px;

    .ant-modal-header {
      padding: unset !important;

      .ant-modal-title {
        font-size: 24px;
        font-weight: 700;
      }
    }

    .ant-modal-body {
      margin-top: 32px;
      font-size: 16px;
      font-weight: 500;
    }

    .ant-modal-close {
      width: 24px;
      height: 24px;

      .ant-modal-close-x {
        height: 100%;
        width: 100%;

        svg {
          fill: #514b4d;
          width: 10px;
          height: 10px;
        }
      }
    }
  }
}
