.ant-custom-checkbox {
  width: 24px;
  height: 24px;
  justify-content: center;

  .ant-checkbox-checked.ant-checkbox-disabled .ant-checkbox-inner {
    background-color: #514b4d;
  }
  .ant-checkbox-checked.ant-checkbox-disabled .ant-checkbox-inner:after {
    border-color: #ffff !important;
  }
}
