.errors-modal {
  border-radius: 8px;
  max-width: 364px;
  &.charge-error {
    .ant-modal-content {
      max-height: 164px;

      .ant-modal-body {
        gap: 16px;
      }
    }
  }
  .ant-modal-content {
    padding: 24px 32px;
    max-width: 364px;
    max-height: 196px;

    .ant-modal-body {
      display: flex;
      flex-direction: column;
      gap: 24px;
      align-items: center;

      .title {
        display: flex;
        gap: 8px;

        svg {
          flex-shrink: 0;
          margin-top: 4px;
        }
      }

      .content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 8px;

        p {
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
          color: #514b4d;
          text-align: center;
          margin-bottom: 0;
        }

        .link {
          cursor: pointer;
          span {
            font-size: 14px;
            font-weight: 500;
            line-height: 22px;
            text-align: center;
            color: #4263eb;
            text-decoration: underline;
          }
        }
      }
    }
  }

  .custom-button__cancel {
    background: #dcdcdc;
    border: 2px solid #7e7e7e;
    padding: 8px 16px;
    border-radius: 8px;
    width: 148px !important;
    height: 44px !important;

    span {
      color: #231f20;
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
    }

    &.custom-ant-button:hover {
      background: #f2f2f2 !important;
      border-color: #9d9d9d !important;
    }
  }
}
