.request-ship-2-buyer {
  position: relative;

  .edit-detail-buyer {
    display: flex;
    align-items: center;
    gap: 24px;

    button {
      background: unset;
      border-radius: 50%;
      box-shadow: 1px 1px 8px 0px #0000001f;
      width: 32px;
      height: 32px;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        rect {
          stroke: #fff;
        }
      }

      &:hover {
        svg {
          fill: #1c86ed;

          path {
            stroke: #fff;
          }
        }
      }
    }
  }

  &__modal-edit-address {
    width: 912px !important;
    height: 934px !important;
    top: 104px !important;

    .ant-modal {
      &-header {
        padding: 0;
        margin-bottom: 32px;
      }

      &-title {
        font-size: 24px;
        font-weight: 700;
        line-height: 36px;
        color: #514b4d;
      }

      &-content {
        padding: 40px 216px;
        border-radius: 20px;

        input {
          outline: none;
          appearance: none;

          &[title] {
            pointer-events: none;
          }

          &:-webkit-autofill {
            background-color: transparent !important;
            box-shadow: 0 0 0px 1000px white inset !important;
            -webkit-text-fill-color: #000 !important;
          }

          &:-webkit-autofill,
          &:-internal-autofill-selected {
            appearance: none !important;
          }
        }

        .ant {
          &-form {
            &-item {
              margin-bottom: 16px;

              label {
                font-size: 16px;
                font-weight: 500;
                line-height: 24px;
                color: #514b4d;
              }

              input {
                width: 400px;
                height: 40px;
                border-radius: 8px;

                &[type='number'] {
                  &::-webkit-inner-spin-button,
                  &::-webkit-outer-spin-button {
                    -webkit-appearance: none;
                  }
                }
              }

              .ant-select-selector {
                border: none;
              }

              &-required,
              .ant-select-selection-item {
                font-size: 16px;
                font-weight: 500;
                line-height: 24px;
                color: #514b4d;

                &::before {
                  display: none !important;
                }
              }
            }
          }

          &-select {
            &-item {
              &-option {
                display: flex;
                align-items: center;
              }
            }
          }
        }
      }

      &-body {
        margin: 0 40px;
      }

      &-footer {
        margin-top: 60px;
        padding: 0;
        display: flex;
        align-items: center;
        gap: 18px;

        button {
          width: 184px;
          height: 44px;
        }
      }
    }
  }

  &-container {
    width: 1016px;
    padding: 20px 80px 60px 80px;
    border-radius: 20px;
    margin: auto;
    box-shadow: 1px 1px 8px 0px #0000001f;
    margin-top: -56px;

    .title {
      font-size: 28px;
      font-weight: 700;
      margin-bottom: 32px;
      color: #231f20;
    }

    p {
      font-size: 20px;
      font-weight: 700;
      height: 32px;
      margin-bottom: unset;
      color: #514b4d;
    }

    .content-partitions {
      border-bottom: 1px solid #7e7e7e;
      display: flex;
      flex-direction: column;
      margin-bottom: 32px;
      padding-bottom: 31px;
      padding-left: 40px;
      gap: 4px;

      &.info {
        flex-direction: row;
        gap: 12px;
        margin-top: 20px;
      }

      &_img {
        width: 96px;
        height: 96px;
        border-radius: 6px;

        .ant-image {
          img {
            border-radius: 6px;
          }
        }
      }

      &_right {
        display: flex;
        flex-direction: column;
        gap: 4px;

        .name-item {
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
          color: #514b4d;
          width: 292px;
          height: unset;
        }
      }

      &-item {
        display: flex;
        flex-direction: row;
        align-items: center;

        .link-user {
          color: #514b4d;

          &:hover {
            text-decoration: underline;
          }
        }

        span {
          font-size: 16px;
          font-weight: 600;
          line-height: 24px;
          color: #514b4d;
        }

        .colon {
          font-size: 14px;
          font-weight: 500;
          line-height: 22px;
          color: #514b4d;
        }

        &-address {
          display: flex;
          justify-content: space-between;
          width: 96px;
          margin-right: 8px;
          font-size: 14px;
          font-weight: 500;
          color: #7e7e7e;
          flex-shrink: 0;

          &.top {
            width: 111px;

            .text {
              font-size: 14px;
              font-weight: 500;
              line-height: 22px;
              color: #7e7e7e;
              height: unset;
            }
          }
        }

        .unit {
          font-size: 12px;
          line-height: 18px;
          font-weight: 500;
        }

        &-size {
          display: flex;
          flex-direction: row;
          gap: 32px;
          font-size: 14px;
          font-weight: 500;
          color: #7e7e7e;
          width: 100%;

          span {
            display: inline-block;
          }

          &-item {
            display: flex;
            justify-content: space-between;
            margin-right: 8px;

            .colon {
              margin-left: 8px;
            }

            &-value {
              font-size: 16px;
              font-weight: 600;
              color: #514b4d;
            }
          }
        }
      }

      .carrier {
        display: flex;
        gap: 14px;
        margin-top: 20px;

        &-item {
          width: 276px;
          height: 116px;
          padding: 12px;
          gap: 8px;
          // padding: 12px;
          display: flex;
          align-items: center;
          border-radius: 6px;
          border: 2px solid transparent;
          background: #fff;
          box-shadow: -2px 2px 8px 0px rgba(0, 0, 0, 0.12);
          cursor: pointer;
          transition: all 0.3s linear;
          position: relative;

          &:hover {
            background: #a4cff8;
          }

          &.active {
            background: #e9f3fe;
            border: 2px solid #d2e7fb;

            &.disabled {
              background-color: unset;
              border: unset;
            }
          }

          .space {
            width: 24px;
            flex-shrink: 0;
          }

          &-information {
            position: absolute;
            top: 8px;
            right: 8px;
            z-index: 3;

            &:hover {
              cursor: pointer;
            }
          }

          .ant-radio-inner {
            width: 20px;
            height: 20px;
            border-color: #514b4d !important;
            border-width: 2px;
            background-color: #fff !important;

            &::after {
              background-color: #514b4d;
              transform: scale(0.6) !important;
            }
          }
        }
      }
    }

    .insurance {
      display: flex;
      justify-content: space-between;
      width: 100%;
      padding-left: unset;
      flex-direction: row;
      padding-bottom: 47px;
      margin-top: 20px;
      gap: 8px;

      div:first-child {
        display: flex;
        align-items: center;

        label:first-child {
          width: 24px;
          height: 24px;
        }
      }

      .ant-checkbox-disabled {
        .ant-checkbox-inner {
          //   width: 18px;
          //   height: 18px;
          background-color: #514b4d;

          &::after {
            // left: 34%;
            border-color: #fff;
          }

          //   &:hover {
          //     background-color: #514b4d !important;
          //     border-color: #514b4d !important;
          //   }
          // }

          // .ant-checkbox-checked .ant-checkbox-inner {
          //   background-color: #514b4d !important;
          //   border-color: #514b4d !important;
        }
      }

      label {
        font-size: 16px;
        font-weight: 500;
        color: #514b4d;
      }

      &_item {
        &_price {
          font-size: 16px;
          font-weight: 600;
          color: #514b4d;
        }

        &_checkbox {
          margin-left: unset !important;
        }
      }
    }

    .size {
      width: 480px;
      gap: 20px;
      margin-top: 20px;

      &.size-package {
        width: 100%;
      }

      &-detail {
        display: flex;
        flex-direction: column;
        gap: 8px;
      }

      .hs {
        display: flex;
        flex-direction: column;
        gap: 8px;
        font-size: 14px;
        font-weight: 500;

        &-price {
          display: flex;
          flex-direction: row;
          width: 240px;
          justify-content: space-between;
          align-items: center;
          gap: 8px;
          color: #514b4d;

          input {
            width: 120px;
            height: 40px;
            border-radius: 8px;
            border: 1px solid #adadad;
            font-size: 16px;
            font-weight: 500;

            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button {
              -webkit-appearance: none;
              margin: 0;
            }
          }

          &.IOSS {
            justify-content: unset;
            gap: unset;
            font-size: 16px;
            font-weight: 600;
            color: #514b4d;
            width: 100%;

            .label {
              width: 120px;
              font-size: 14px;
              font-weight: 500;
              color: #7e7e7e;
            }
          }
        }
      }

      .centimeter {
        font-size: 16px;
        font-weight: 500;
        color: #514b4d;
      }

      .kilogram {
        font-size: 16px;
        font-weight: 500;
        color: #514b4d;

        span {
          margin-left: 4px;
        }
      }

      .btn-group {
        display: flex;
        flex-direction: column;
        gap: 4px;

        button {
          font-size: 14px;
          font-weight: 600;
          width: 102px;
          height: 32px;
        }

        div {
          font-size: 10px;
          font-weight: 500;
          color: #514b4d;
        }
      }
    }

    .address {
      margin-top: 20px;
    }

    .carrier {
      padding: unset;
      border-bottom: unset;
    }

    .total {
      display: flex;
      justify-content: space-between;
      font-size: 18px;
      font-weight: 700;

      div:first-child {
        font-size: 20px;
        font-weight: 700;
        color: #514b4d;
      }

      div:last-child {
        font-size: 18px;
        font-weight: 700;
        font-size: 20px;
        font-weight: 700;
        color: #231f20;
      }
    }
  }

  &-button-wrap {
    display: flex;
    gap: 24px;
    justify-content: end;
    margin-top: 40px;
  }

  .btn-back {
    background-color: #fff;
    padding: 0;
  }

  .loading {
    z-index: 1;
    width: 100%;
    height: 100%;
    justify-content: center;
    top: 0;
    background: #fff;
    opacity: 0.5;
    position: absolute;

    .ant-spin {
      top: 50%;
      left: 50%;
    }
  }
}

.modal-confirm-ship-by-date {
  .ant-modal-content {
    padding: 24px 32px;
    max-width: 464px;
    max-height: 212px;

    .ant-modal-body {
      display: flex;
      flex-direction: column;
      gap: 24px;
      align-items: center;

      .content {
        display: flex;
        gap: 8px;

        svg {
          flex-shrink: 0;
        }

        p {
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
          color: #514b4d;
        }
      }
    }
  }

  &__cancel {
    background: #dcdcdc;
    border: 2px solid #7e7e7e;
    padding: 8px 16px;
    border-radius: 2px;
    width: 148px !important;
    height: 44px !important;

    span {
      color: #231f20;
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
    }

    &.custom-ant-button:hover {
      background: #f2f2f2 !important;
      border-color: #9d9d9d !important;
    }
  }
}
