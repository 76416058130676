.modal-styled {
  .button-wrap {
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 24px;
    margin-top: 60px;
  }

  .ant-modal-title {
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .ant-modal-header {
    padding: 20px 40px;
    margin-bottom: 0;
  }
  .ant-modal-footer {
    padding-top: 10px;
  }
  .ant-modal-content {
    padding: 24px 32px;
    //top: 80px;
  }
  .text-modals {
    color: #514b4d;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
  }

  .ant-modal-body {
    &::-webkit-scrollbar {
      width: 4px;
      height: 4px;
    }

    &::-webkit-scrollbar-track {
      background: #dbdddf;
      border-radius: 8px;
    }

    &::-webkit-scrollbar-thumb {
      background: #00297a;
      border-radius: 8px;
    }
  }
}
