.ant-custom-search-input {
  width: 400px;
  height: 40px;
  border-radius: 24px;
  border: 1.5px solid #adadad;
}

.anticon svg {
  height: 24px;
  width: 24px;
}

.ant-input-affix-wrapper > input.ant-input {
  color: #514b4d;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  background: #fff;
}

.ant-custom-input {
  height: 54px;
  width: 100%;
  border-radius: 8px;
  color: #514b4d;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  border: 1px solid #adadad;
  background: #fff;
  padding: 15px 20px 15px 16px;
  &::placeholder {
    color: #9d9d9d;
  }
  input {
    font-size: 16px;
  }

  input[type='password'] {
    &::placeholder {
      font-size: 16px;
    }
  }
}
.ant-input-suffix {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}
.ant-form-item .ant-form-item-explain-error {
  margin-top: 4px;
  color: #f43258;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
}
.ant-custom-input-area {
  height: 54px;
  width: 100%;
  border-radius: 8px;
  border: 1px solid #adadad;
  background: #fff;

  input {
    font-size: 16px;
  }
}

.title-input-mark {
  color: #f43258;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}
