.ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
  color: unset;
  background: unset;
}

button {
  border: none;
  cursor: pointer;
  transition: all 0.3s linear;
}

p {
  margin-bottom: 0;
}

.button-large-primary {
  color: #fff;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  display: flex;
  width: 544px;
  height: 54px;
  padding: 12px;
  justify-content: center;
  align-items: center;
  border-radius: 32px;
  background: #1c86ed;

  &:hover {
    background: #77b6f4;
  }

  &.disable {
    color: #9d9d9d;
    background: #dcdcdc;
    cursor: unset;

    &:hover {
      background: #dcdcdc;
    }
  }

  &.active {
    box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.25);

    &:hover {
      background: #1c86ed;
      cursor: unset;
    }
  }
}

.button-normal-primary {
  color: #fff;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  display: flex;
  width: 148px;
  height: 44px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: #1c86ed;

  &:hover {
    background: #77b6f4;
  }

  &.disable {
    color: #9d9d9d;
    background: #dcdcdc;
    cursor: unset;

    &:hover {
      background: #dcdcdc;
    }
  }

  &.active {
    box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.25);

    &:hover {
      background: #1c86ed;
      cursor: unset;
    }
  }
}

.button-small-primary {
  color: #fff;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  display: flex;
  width: 102px;
  height: 32px;
  padding: 7px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background: #1c86ed;

  &:hover {
    background: #77b6f4;
  }

  &.disable {
    color: #9d9d9d;
    background: #dcdcdc;
    cursor: unset;

    &:hover {
      background: #dcdcdc;
    }
  }

  &.active {
    box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.25);

    &:hover {
      background: #1c86ed;
      cursor: unset;
    }
  }
}

.button-small-icon-primary {
  display: flex;
  width: 116px;
  height: 32px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 16px;
  background: #1c86ed;

  p {
    color: #fff;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
  }

  span {
    display: inline-flex;
    width: 20px;
    height: 20px;
    align-items: center;
    justify-content: center;
    transition: all 0.3s linear;

    path {
      transition: all 0.3s linear;
    }
  }

  &:hover {
    height: 40px;
    border-radius: 24px;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.16);
  }

  &:hover span {
    width: 24px;
    height: 24px;
    background: #fff;
    border-radius: 100%;
  }

  &:hover span path {
    fill: #1c86ed;
  }

  &.disable {
    background: #dcdcdc;

    p {
      color: #9d9d9d;
    }

    span {
      path {
        fill: #9d9d9d;
      }
    }

    cursor: unset;

    &:hover {
      height: 32px;
      box-shadow: none;
    }

    &:hover span {
      width: 20px;
      height: 20px;
      background: #dcdcdc;
    }

    &:hover span path {
      fill: #9d9d9d;
    }
  }

  &.active {
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.16);
    height: 40px;
    border-radius: 24px;
    background: #0468c9;

    span {
      width: 24px;
      height: 24px;
      background: #fff;
      border-radius: 100%;

      path {
        fill: #1c86ed;
      }
    }

    &:hover {
      background: #0468c9;
      cursor: unset;
      box-shadow: none;
    }
  }

  &.request {
    color: #9d9d9d;
    background: #adadad;

    &:hover {
      height: 32px;
      box-shadow: none;
      cursor: unset;
    }

    span {
      display: none;
    }
  }
}

.button-icon-primary {
  display: inline-flex;
  padding: 10px 10px 6px 6px;
  align-items: flex-start;
  border-radius: 47px;
  background: #1c86ed;

  &:hover {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.2) 100%), #1c86ed;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.16);
  }

  &.disable {
    background: #dcdcdc;

    svg {
      path {
        stroke: #9d9d9d;
      }
    }

    &:hover {
      background: #dcdcdc;
      box-shadow: none;
      cursor: unset;
    }
  }

  &.pressed {
    background: #0b6ccb;

    &:hover {
      background: #0b6ccb;
      box-shadow: none;
      cursor: unset;
    }
  }
}

.button-large-gray {
  color: #231f20;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  display: flex;
  width: 544px;
  height: 54px;
  padding: 12px;
  justify-content: center;
  align-items: center;
  border-radius: 32px;
  border: 2px solid #9d9d9d;
  background: #dcdcdc;

  &:hover {
    background: #f2f2f2;
  }

  &.disable {
    color: #9d9d9d;
    background: #dcdcdc;
    border: none;
    cursor: unset;

    &:hover {
      background: #dcdcdc;
    }
  }

  &.active {
    border: 2px solid #adadad;
    box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.25);

    &:hover {
      background: #dcdcdc;
      cursor: unset;
    }
  }
}

.button-normal-gray {
  color: #231f20;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  display: flex;
  width: 148px;
  height: 44px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  border: 2px solid #9d9d9d;
  background: #dcdcdc;

  &:hover {
    background: #f2f2f2;
  }

  &.disable {
    border: none;
    color: #9d9d9d;
    background: #dcdcdc;
    cursor: unset;

    &:hover {
      background: #dcdcdc;
    }
  }

  &.active {
    box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.25);

    &:hover {
      background: #dcdcdc;
      cursor: unset;
    }
  }
}

.button-small-gray {
  color: #231f20;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  display: flex;
  width: 102px;
  height: 32px;
  padding: 6px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border: 2px solid #9d9d9d;
  background: #dcdcdc;

  &:hover {
    background: #f2f2f2;
  }

  &.disable {
    border: none;
    color: #9d9d9d;
    background: #dcdcdc;
    cursor: unset;

    &:hover {
      background: #dcdcdc;
    }
  }

  &.active {
    box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.25);

    &:hover {
      background: #dcdcdc;
      cursor: unset;
    }
  }
}

.button-ebay-normal {
  display: inline-flex;
  height: 44px;
  padding: 12px 30px;
  justify-content: center;
  align-items: center;
  gap: 16px;
  flex-shrink: 0;
  border-radius: 8px;
  border: 2px solid #1c86ed;
  background: #fff;

  p {
    color: #1c86ed;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    /* 150% */
  }

  &_svg {
    position: relative;
    width: 32px;
    height: 32px;

    svg {
      position: absolute;
      top: 0;
      left: 0;
      width: 32px;
      height: 32px;
    }

    .svg-green {
      transition: all 0.3s linear;
    }

    .svg-white {
      opacity: 0;
      pointer-events: none;
      transition: all 0.3s linear;
    }

    .svg-disable {
      opacity: 0;
      pointer-events: none;
      transition: all 0.3s linear;
    }
  }

  &:hover {
    background: #1c86ed;
  }

  &:hover p {
    color: #fff;
  }

  &:hover .svg-green {
    opacity: 0;
    pointer-events: none;
  }

  &:hover .svg-white {
    opacity: 1;
    pointer-events: auto;
  }

  &.disable {
    border: 2px solid #9d9d9d;
    cursor: unset;

    p {
      color: #9d9d9d;
    }

    .button-ebay-normal_svg {
      .svg-green {
        opacity: 0;
        pointer-events: none;
        transition: all 0.3s linear;
      }

      .svg-white {
        opacity: 0;
        pointer-events: none;
        transition: all 0.3s linear;
      }

      .svg-disable {
        opacity: 1;
        pointer-events: auto;
        transition: all 0.3s linear;
      }
    }

    &:hover {
      background: #fff;
    }
  }
}

.button-ebay-small {
  display: inline-flex;
  width: 102px;
  height: 32px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  border-radius: 4px;
  border: 2px solid #1c86ed;
  background: #fff;

  .anticon-spin {
    width: 16px;
    height: 16px;
    position: absolute;
    left: 6px;
    top: 4px;
  }

  p {
    color: #1c86ed;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    /* 150% */
    margin: unset;
  }

  &_svg {
    position: relative;
    width: 24px;
    height: 24px;

    svg {
      position: absolute;
      top: 0;
      left: 0;
      width: 24px;
      height: 24px;
    }

    .svg-green {
      transition: all 0.3s linear;
    }

    .svg-white {
      opacity: 0;
      pointer-events: none;
      transition: all 0.3s linear;
    }

    .svg-disable {
      opacity: 0;
      pointer-events: none;
      transition: all 0.3s linear;
    }
  }

  &:hover {
    background: #1c86ed !important;
  }

  &:hover p {
    color: #fff;
  }

  &:hover .svg-green {
    opacity: 0;
    pointer-events: none;
  }

  &:hover .svg-white {
    opacity: 1;
    pointer-events: auto;
  }

  &.disable {
    border: 2px solid #9d9d9d;
    cursor: unset;

    p {
      color: #9d9d9d;
    }

    .button-ebay-small_svg {
      .svg-green {
        opacity: 0;
        pointer-events: none;
        transition: all 0.3s linear;
      }

      .svg-white {
        opacity: 0;
        pointer-events: none;
        transition: all 0.3s linear;
      }

      .svg-disable {
        opacity: 1;
        pointer-events: auto;
        transition: all 0.3s linear;
      }
    }

    &:hover {
      background: #fff;
    }
  }
}

.button-repsponding {
  display: flex;
  width: 102px;
  height: 28px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  flex-shrink: 0;
  border-radius: 4px;
  background: #fff;

  p {
    color: #1c86ed;
    text-align: center;
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    /* 160% */
  }

  &:hover {
    border: 1px solid #9d9d9d;
  }
}

.button-submit-gray {
  display: flex;
  width: 148px;
  height: 44px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  border: 2px solid #7e7e7e;
  background: #dcdcdc;
  color: #231f20;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  transition: all 0.3s linear;

  &:hover {
    background: #f2f2f2 !important;
  }
}

.button-cancel {
  display: flex;
  width: 148px;
  height: 44px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  border: 2px solid #9d9d9d;
  background: #dcdcdc;
  color: #514b4d;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  transition: all 0.3s linear;

  &:hover {
    background: #f2f2f2 !important;
  }
}

.button-agree {
  display: flex;
  width: 148px;
  height: 44px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  background: #1c86ed;
  color: #fff;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  /* 150% */
  transition: all 0.3s linear;

  &:hover {
    background: #77b6f4 !important;
  }

  &.disabled {
    background: #dcdcdc;
    cursor: not-allowed !important;
    pointer-events: all !important;
    color: #7e7e7e;

    &:hover {
      background: #dcdcdc !important;
    }
  }
}

.button-tertiary-normal {
  display: flex;
  width: 148px;
  height: 44px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  border-radius: 8px;
  border: 2px solid #1c86ed;
  background: #fff;
  color: #1c86ed;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;

  /* 150% */
  &:hover {
    border: 2px solid #77b6f4;
    color: #77b6f4;
  }

  &.disable {
    color: #9d9d9d;
    border: 2px solid #9d9d9d;
    cursor: unset;
  }

  &.active {
    box-shadow: -4px 4px 4px 0px rgba(0, 0, 0, 0.25);
    cursor: unset;

    &:hover {
      border: 2px solid #1c86ed;
      color: #1c86ed;
    }
  }
}

.button-tertiary-small {
  display: flex;
  width: 120px;
  height: 40px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  border-radius: 8px;
  border: 2px solid #1c86ed;
  background: #fff;
  color: #1c86ed;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;

  &:hover {
    border: 2px solid #77b6f4;
    color: #77b6f4;
  }

  &.disable {
    color: #9d9d9d;
    border: 2px solid #9d9d9d;
    cursor: unset;
  }

  &.active {
    box-shadow: -4px 4px 4px 0px rgba(0, 0, 0, 0.25);
    cursor: unset;

    &:hover {
      border: 2px solid #1c86ed;
      color: #1c86ed;
    }
  }
}

.button-tertiary-tiny {
  display: flex;
  width: 100px;
  height: 32px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  border-radius: 8px;
  border: 2px solid #1c86ed;
  background: #fff;
  color: #1c86ed;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;

  &:hover {
    border: 2px solid #77b6f4;
    color: #77b6f4;
  }

  &.disable {
    color: #9d9d9d;
    border: 2px solid #9d9d9d;
    cursor: unset;
  }

  &.active {
    box-shadow: -4px 4px 4px 0px rgba(0, 0, 0, 0.25);
    cursor: unset;

    &:hover {
      border: 2px solid #1c86ed;
      color: #1c86ed;
    }
  }
}

.button-ship-request {
  display: flex;
  width: 84px;
  height: 32px;
  align-items: center;
  padding-left: 16px;
  flex-shrink: 0;
  border-right: 1px solid #dcdcdc;
  border-radius: 16px 0px 0px 16px;
  background: #1c86ed;
  color: #fff;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;

  &:hover {
    background: #499ef1;
  }

  &.disable {
    color: #9d9d9d;
    background: #dcdcdc;
    cursor: unset;
  }

  &.active {
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    cursor: unset;

    &:hover {
      background: #1c86ed;
    }
  }
}

.button-ship-request-option {
  display: flex;
  width: 32px;
  height: 32px;
  padding: 8px 10px 4px 2px;
  align-items: center;
  flex-shrink: 0;
  border-radius: 0px 16px 16px 0px;
  background: #1c86ed;

  &:hover {
    background: #499ef1;
  }

  &.disable {
    color: #9d9d9d;
    background: #dcdcdc;
    cursor: unset;
  }

  &.active {
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    cursor: unset;

    &:hover {
      background: #1c86ed;
    }
  }
}

.checkbox-custom {
  &__checkbox-title {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: center;
    color: #514b4d;
  }

  // .ant-checkbox-wrapper {
  //   &:hover {
  //     .ant-checkbox-inner {
  //       background-color: #514b4d !important;
  //     }
  //   }
  // }

  // .ant-checkbox {
  //   .ant-checkbox-inner {
  //     width: 18px;
  //     height: 18px;
  //     position: relative;
  //     border: 1px solid #514b4d !important;

  //     &::after {
  //       content: '';
  //       position: absolute;
  //       top: 80%;
  //       left: 70%;
  //       transform: translate(-50%, -50%);
  //       height: 100%;
  //       width: 100%;
  //       background-image: url(../../../public/assets/icons/checked.svg);
  //       background-repeat: no-repeat;
  //       border: unset !important;
  //     }
  //   }

  //   &-checked {
  //     .ant-checkbox-inner {
  //       background: #514b4d;
  //     }
  //   }
  // }
}

.checkbox-slip {
  position: relative;
  width: 44px;
  height: 24px;

  input {
    width: 44px;
    height: 24px;
    position: relative;
    z-index: 1;
    opacity: 0;
    cursor: pointer;

    &:checked ~ .checkslip {
      background: #fff;
      border: 2px solid #1c86ed;
    }

    &:checked ~ .checkslip::after {
      background: #1c86ed;
      transform: translate(18px, -50%);
    }
  }

  .checkslip {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 2px solid #9d9d9d;
    border-radius: 12px;
    background: rgba(0, 0, 0, 0.2);
    transition: all 0.3s linear;

    &::after {
      content: '';
      position: absolute;
      width: 18px;
      height: 18px;
      top: 50%;
      transform: translateY(-50%);
      left: 2px;
      border-radius: 100%;
      background: #514b4d;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
      transition: all 0.3s linear;
    }
  }
}

.checkbox-slip-gray {
  position: relative;
  width: 44px;
  height: 20px;

  input {
    width: 44px;
    height: 20px;
    position: relative;
    z-index: 1;
    opacity: 0;
    cursor: pointer;

    &:checked ~ .checkslip-gray {
      background: #514b4d;
    }

    &:checked ~ .checkslip-gray::after {
      transform: translate(24px, -50%);
    }
  }

  .checkslip-gray {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 12px;
    background: #adadad;
    transition: all 0.3s linear;

    &::after {
      content: '';
      position: absolute;
      width: 16px;
      height: 16px;
      top: 50%;
      transform: translateY(-50%);
      left: 2px;
      border-radius: 100%;
      background: #ffffff;
      transition: all 0.3s linear;
    }
  }
}

.radio-custom-primary {
  position: relative;
  width: 22px;
  height: 22px;

  input {
    width: 22px;
    height: 22px;
    position: relative;
    z-index: 1;
    opacity: 0;
    cursor: pointer;

    &:checked ~ .radio-check-primary {
      background: #1c86ed33;
      border: none;
    }

    &:checked ~ .radio-check-primary::after {
      display: block;
    }
  }

  .radio-check-primary {
    position: absolute;
    width: 22px;
    height: 22px;
    top: 0;
    left: 0;
    border: 2px solid #9f9f9f;
    border-radius: 100%;

    &::after {
      content: '';
      position: absolute;
      width: 16px;
      height: 16px;
      background: #1c86ed;
      border-radius: 100%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: none;
    }
  }
}

.radio-custom-black {
  position: relative;
  width: 22px;
  height: 22px;

  input {
    width: 22px;
    height: 22px;
    position: relative;
    z-index: 1;
    opacity: 0;
    cursor: pointer;

    &:checked ~ .radio-check-black::after {
      display: block;
    }
  }

  .radio-check-black {
    position: absolute;
    width: 22px;
    height: 22px;
    top: 0;
    left: 0;
    border: 2px solid #514b4d;
    border-radius: 100%;

    &::after {
      content: '';
      position: absolute;
      width: 12px;
      height: 12px;
      background: #514b4d;
      border-radius: 100%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: none;
    }
  }
}

.button-agree-white {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  color: #1c86ed;
  background-color: #fff;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  border: 2px solid #1c86ed;
  transition: all 0.3s linear;

  &.large {
    width: 148px;
    height: 44px;
  }

  &.small {
    width: 100px;
    height: 32px;
  }

  &.normal {
    width: 120px;
    height: 40px;
  }

  &:hover {
    opacity: 0.6 !important;
  }

  &.disabled {
    cursor: not-allowed !important;
    pointer-events: all !important;
    color: #7e7e7e;
    border-color: #7e7e7e;

    // &:hover {
    //   background: #dcdcdc !important;
    // }
  }
}

.btn-arrow-back {
  background-color: #fff;
  margin: unset;
  padding: unset;

  &:hover {
    svg {
      circle {
        fill: #1c86ed;
      }

      path {
        fill: #fff;
      }
    }
  }
}
