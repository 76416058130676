//
@font-face {
  font-family: 'Noto Sans JP', sans-serif;
  font-style: normal;
  font-weight: 300;
  src: url('fonts/NotoSansJP-Black.ttf') format('truetype');
}

//
@font-face {
  font-family: 'Noto Sans JP', sans-serif;
  font-style: normal;
  font-weight: 400;
  src: url('fonts/NotoSansJP-Regular.ttf') format('truetype');
}

//
@font-face {
  font-family: 'NotoSansJP-medium';
  font-style: normal;
  font-weight: 500;
  src: url('fonts/NotoSansJP-Medium.ttf') format('truetype');
}

//
@font-face {
  font-family: 'Noto Sans JP', sans-serif;
  font-style: normal;
  font-weight: 600;
  src: url('fonts/NotoSansJP-SemiBold.ttf') format('truetype');
}

//
@font-face {
  font-family: 'Noto Sans JP', sans-serif;
  font-style: normal;
  font-weight: 700;
  src: url('fonts/NotoSansJP-Bold.ttf') format('truetype');
}

//
@font-face {
  font-family: 'Noto Sans JP', sans-serif;
  font-style: normal;
  font-weight: 800;
  src: url('fonts/NotoSansJP-ExtraBold.ttf') format('truetype');
}

//
@font-face {
  font-family: 'Market Sans';
  font-style: normal;
  font-weight: 700;
  src: url('fonts/MARKETSANS-BOLD.TTF') format('truetype');
}

//
@font-face {
  font-family: 'MarketSans-medium';
  font-style: normal;
  font-weight: 500;
  src: url('fonts/MARKETSANS-MEDIUM.TTF') format('truetype');
}

//
@font-face {
  font-family: 'Market Sans';
  font-style: normal;
  font-weight: 400;
  src: url('fonts/MARKETSANS-REGULAR.TTF') format('truetype');
}

//
@font-face {
  font-family: 'Market Sans';
  font-style: normal;
  font-weight: 600;
  src: url('fonts/MARKETSANS-SEMIBOLD.TTF') format('truetype');
}

//
@font-face {
  font-family: 'Market Sans';
  font-style: normal;
  src: url('fonts/MARKETSANS-THIN.TTF') format('truetype');
}
