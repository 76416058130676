.sidebar-logo-container {
  padding: 32px;
  padding: 0px 26px;
  background-color: #293748 !important;
  background: #293748;
  min-width: fit-content;
}

.slider {
  ant-layout-sider ant-layout-sider-dark layout-sider {
    flex: 0 0 250px !important;
    max-width: 250px !important;
    min-width: 250px !important;
    width: 250px !important;
  }
  display: flex;
  flex-direction: column;
  height: 100%;

  .line {
    width: 100%;
    height: 1px;
    background: #f2f2f2;
    margin-bottom: 8px;
  }

  .text-bottom {
    margin-top: auto;
    margin-bottom: 20px;
    .top {
      display: flex;
      margin-bottom: 4px;
      justify-content: center;
      align-items: center;
      gap: 16px;
    }

    color: #dcdcdc;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
  }
}

.ant-custom-menu {
  // border-top: 2px solid var(--White-5, #dcdcdc);
  padding-top: 10px;
  background-color: #293748;
  margin-right: 0 !important;
  font-size: 14px;
  .ant-menu-item {
    padding: 7px 0px 7px 20px !important;
    margin-right: 0 !important;
    .ant-menu-title-content {
      color: #dcdcdc;
      font-weight: 500;
    }
  }
  .ant-menu-item-selected {
    background-color: unset !important;
    .ant-menu-title-content {
      span {
        color: #ffffff;
        font-weight: 700;
      }
    }

    .icon-seleted {
      display: block !important;
    }
  }
}
