.header {
  border-bottom: 2px solid #dcdcdc;
  background: #293748 !important;
  align-items: center;

  &-logo {
    height: 100%;
    gap: 12px;
    align-items: center;

    h2 {
      font-size: 20px;
      font-weight: 600;
      line-height: 32px;
      color: #fff;
      margin-bottom: 0;
    }

    span {
      margin-left: 24px;
      height: 100%;
      display: flex;
      align-items: center;
      // width: 240px;
    }
  }

  &-expired {
    &-list {
      display: flex;
      flex-direction: column;
      color: #fff;
      justify-content: space-between;
      line-height: normal;

      span {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        width: unset;
        max-width: 500px;
        display: inline-block;
        font-size: 12px;
        margin-left: unset;
      }
    }
  }

  &-right {
    align-items: center;

    &-biz {
      &-message {
        display: flex;
        margin-right: 22px;
        position: relative;

        &-number {
          width: 16px;
          color: #fff;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 50px;
          width: 17px;
          height: 18px;
          flex-shrink: 0;
          position: absolute;
          right: -15px;
        }
      }
    }

    &-notifications {
      display: flex;
      align-items: center;
      margin-right: 16px;

      svg {
        cursor: pointer;
      }

      &-number {
        // width: 16px;
        color: #fff;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 6px;
        width: 17px;
        height: 18px;
        flex-shrink: 0;
      }

      &-setting {
        margin-right: 22px;
      }
    }

    .task {
      display: flex;
      align-items: center;

      svg {
        width: 25px;
        height: 25px;
        cursor: pointer;
      }

      &-count {
        font-size: 14px;
        font-weight: 600;
        line-height: 22px;
        color: #fff;
        margin-top: -22px;
        width: 24px;
        height: 14px;
      }
    }
  }
}

.custom-avatar {
  border: 2.5px solid white;
  background-color: #fff;
}

.setting-drop {
  display: flex;
  width: 280px;
  padding: 16px 8px;
  flex-direction: column;
  // height: 188px;

  &-text-menu {
    color: #514b4d;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    display: flex;
    height: 46px;
    align-items: center;
    align-self: stretch;
    width: 100% !important;
  }
}

.dropdown-menu-biz {
  height: 80vh;
  overflow-y: auto;

  .biz-drop {
    position: fixed !important;
    top: 50px !important;
    right: 156px !important;
    width: 400px;
    padding: 0 !important;
    overflow-y: scroll;
    max-height: calc(100vh - 48px);

    li {
      padding: 16px !important;
      height: 96px;
      border-bottom: 0.5px solid #dcdcdc;
      border-radius: unset !important;
      cursor: default !important;

      &:hover {
        background-color: #e9f3fe !important;

        // .bix-drop-item {
        //   &-info {
        //     &-sku {
        //       text-decoration: underline;
        //     }
        //   }
        // }
        .text-decoration {
          text-decoration: underline;
        }
      }

      &:last-child {
        border-bottom: unset;
      }

      .bix-drop-item {
        display: flex;

        &-image {
          border: 1px solid transparent;
          overflow: hidden;
          border-radius: 4px;
          margin-right: 8px;

          img {
            width: 64px;
            height: 64px;
          }
        }

        &-info {
          flex: 1;

          &-title {
            font-size: 12px;
            font-weight: 600;
            color: #514b4d;
            line-height: 18px;
            margin-bottom: 4px;
            text-wrap: wrap;
          }

          &-sku {
            font-size: 12px;
            font-weight: 600;
            color: #514b4d;
            text-wrap: wrap;
            line-height: 18px;
          }

          &-date {
            font-size: 12px;
            font-weight: 500;
            color: #7e7e7e;
            line-height: 18px;
          }
        }

        &-status {
          align-self: center;
        }
      }
    }
  }

  .biz-drop::-webkit-scrollbar {
    display: none;
  }
}

.dropdown-menu-biz::-webkit-scrollbar {
  display: none;
}
